<template>
  <vs-card>
    <div slot="header">
      <h4>{{ item.name }}</h4>
      <p
        class=""
        style="color: rgb(184, 194, 204);"
      >
        {{ item.desc }}
      </p>
    </div>
    <vs-row
      vs-type="flex"
      vs-justify="space-between"
    >
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="10"
      >
        <tm-select
          class="w-full"
          :options="item.field"
          validation-rules=""
          v-model="item.value"
        />
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="2"
      >
        <vs-button
          type="filled"
          @click.prevent="configUpdated"
        >
          حفظ
        </vs-button>
      </vs-col>
    </vs-row>
  </vs-card>
</template>
<script>
import TmSelect from "@/app/shared/shared-components/TmSelect";

export default {
  name: "SelectConfig",
  components: {TmSelect},
  methods: {
    configUpdated() {
      this.$emit('configUpdated', this.item);
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>
